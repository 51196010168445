<template>
  <div class="main-wrapper">
    <!-- <title-bar title="Advertise" subtitle=""></title-bar> -->
    <div
      id="advertise"
      class="gradient"
    >
      <div class="title-overlay" />
    </div>
    <div class="">
      <div class="container">
        <div class="pricing-n-plan">
          <div class="col-lg-8 mx-auto ">
            <div class="row" >
              <div class="col-9">
                <p class="lead">
                  Pick a plan and start your Thriggle experience today.
                </p>
              </div>
              <div class="col-3">
                <button class=" btn btn-1 btn-outline-success " style="margin-left:-50px">Sign Up Now</button>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-lg-2" />
            <div class="col-lg-4 mb-4">
              <div class=" full-height" style="border:1px solid #f1f0ee;border-top-right-radius:10px;border-top-left-radius:10px">
                <div class="pricing-wrapper text-center">
                  <div class="bg-primary py-2" style="border-top-right-radius:10px;border-top-left-radius:10px">
                    <h2 class="text-white">
                      <img src="/static/images/thriggle-resived-logo.png" width="180" height="55" alt="">
                    </h2>
                     <!-- <h5 class="text-white mb-0">Promote your Event HERE </h5> -->
                    <p class="text-white mb-0">Promote your bar, or restaurant HERE Ideal for 1 to 1,000 locations</p>
                  </div>
                  <div class="p-4">
                    <h3 class="text-primary mb-0">
                      $5/Weekly
                    </h3>
                  </div>
                  <div class="bg-secondary ">
                    <ul class="pricing-listing pl-2 m-0 font-weight-bold  text-left" >
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Targeted ads</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Unlimited ads per zip code</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Unlimited ad updates</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Priority sponsorship and listings</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp; Cross-marketing on Zip Code Pros</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp; Searchable by zip code</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp; Searchable by city </li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Searchable on map</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp; Searchable on the Thriggle app</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp; Mobile Friendly  </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-4 ">
                      <!-- <a
                        href="#"
                        class="btn btn-1 btn-outline-success"
                      >Free Sign-up </a> -->
                      <router-link to="sign-up" class="btn btn-1 btn-outline-success"> Free Sign-up </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div  style="border:1px solid #f1f0ee;border-top-right-radius:10px;border-top-left-radius:10px">
                <div class="pricing-wrapper text-center">
                  <div class="bg-success py-2 px-4"  style="border-top-right-radius:10px;border-top-left-radius:10px">
                    <h2 class="text-white">
                      <img src="/static/images/zipcodepro-logo.png" width="200" height="30" alt="">
                    </h2>
                    <p class="text-white mb-0 pb-2">
                     Promote your bar, or restaurant HERE Ideal for 1 to 1,000 locations
                    </p>
                  </div>
                  <div class="p-4">
                    <h3 class="text-success mb-0">
                      $5/Weekly
                    </h3>
                  </div>
                  <div class="bg-secondary">
                    <ul class="pricing-listing pl-2 m-0 font-weight-bold text-left ">
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;   Targeted ads</li>
                       <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  One ad per zip code</li>
                       <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Unlimited ad updates</li>
                       <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Priority placement on Zip Code Pros</li>
                       <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Cross-marketing on Thriggle</li>
                       <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;   Searchable by zip code</li>
                       <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Searchable by city</li>
                       <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;   Searchable on map</li>
                       <li><span class="theme-color font-weight-bold red">✖ </span>&nbsp;   Searchable on the Thriggle app</li>
                       <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;   Mobile Friendly</li>
                       <hr>
                       <li class="p-2">
                        Rather than getting bunched together with an ocean of competitors, our platform gives you priority placement. If you’re the first business in your ZIP code to register under a category, you OWN it. This means when local customers search for your type of business, they’ll find you!
                       </li>

                    </ul>
                  </div>
                  <div>
                    <div class="p-4">
                      <!-- <a
                        href="#"
                        class="btn btn-1 btn-outline-success"
                      >  <span class="color:#000000 !important;">Free</span> Sign-up </a> -->
                        <router-link to="sign-up" class="btn btn-1 btn-outline-success"> Free Sign-up </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleBar from 'Components/globalFrontendComponents/TitleBar';
export default {
	components: {
		TitleBar: TitleBar
	}
};
</script>

<style scoped>
#advertise {
    position: relative;
    padding: 70px 0;
    padding: 8rem 0;
    background: url(/static/images/advertise.png);
    background-size: cover;
    background-position: center center;
    pointer-events: none;
    color: #fff;
}
.theme-color{
  color: #5e72e4;
}
hr{
  margin-top:4px !important;
  margin-bottom:4px !important;
}
.full-height{
  height: 100%;
}
.btn-outline-success {
    color: #2dce89;
    background-color: transparent;
    background-image: none;
    border-color: #2dce89;
    position: absolute!important;
    bottom: 10px;
    left: 100px;
    left: 50% - 130px;
    /* margin: 0 auto; */
    display: block;
    margin: 0 auto;
}
.p-4{
  margin-top: 30px;
}
.red{
  color:red
}
</style>